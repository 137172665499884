import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="The Best Smile On The Bachelor | The Smile Stylist" 
    description="Let’s see who has the best smile on the Bachelor and who can win the Golden Veneers Award. Here are some shortlisted names." 
    keywords="Smile Makeover, Smile Consultation" />
    <main class="site-content prindex" role="main">
    <section id="main-top-img" class="blog hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 sec-title wow animated fadeInDown">
            <div class="blog-news-art">
              <h1>The Best Smile on the Bachelor</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div class="container bloghack_images">
        <div class="row">
          <div class="col-sm-8 wow animated fadeInRight">
            <p>With “The Bachelor” wrapping up this week, there is not much left to do but forget about what happened and try to smile!! Did Arie actually pull a “Josh McDaniels” and back out of his engagement only to go chase another woman?  Did he actually wait to break up with her until the cameras were present? UNBELIEVEABLE. All we can do now is try to forget about it, put a nice smile on our faces and try to move forward.</p>
            <p>With the conclusion of yet another riveting season it is time to crown the best smile on reality TV for another season. This is the hard-hitting journalism you have come to expect here at The Smile Stylist, and we cannot wait to see who is awarded the Golden Veneers, or as we call them in the business, the Venny’s this year. Without further adieu, here are the five finalists for the 2018 Golden Veneer’s: The Bachelor edition:</p>
            <h3>Becca K. </h3>
            <img src={ require("../../images/desktop/_blog/pics/Becca-K.png" )} alt="Becca" />
            <p>Becca K, the 27-year-old publicist from Prior Lake Minnesota wowed the judges this year with her ear to ear smile. While her smile could be likened to past winners of the Golden Veneers, the judging panel did appreciate her gum line. She is certainly a finalist, and although she might not have gotten the final red rose, she may, in fact, win the more coveted golden veneer.</p>
            <h3>Jenny </h3>
            <img src={ require("../../images/desktop/_blog/pics/jenny Bachelor.png" )} alt="Jenny" />
            <p>A classic Midwest smile ensured that Jenny got into the top five for this year's competition. With a million dollar smile and some of the whitest teeth that the judges have seen in the past five years, it is no wonder that she won America’s heart and dentists hearts across the world. This 25-year-old graphic designer from Northbrook, Illinois is in tough competition but is a favourite among the dental hygienists.</p>
            <h3>Lauren G. </h3>
            <img src={ require("../../images/desktop/_blog/pics/Lauren G Bachelor.png" )} alt="Lauren" />
            <p>Lauren G. certainly wins the best smile by a Lauren… but that certainly does not mean she is running away with a Golden Veneer. Her smile is something out of a storybook, with the perfect combination of the gum line and pearly white teeth. Plus, it helps that grandmas have voted her most pinchable cheeks, which naturally is always a factor in determining a Venny</p>
            <h3>Chris Harrison </h3>
            <img src={ require("../../images/desktop/_blog/pics/Chris Harrison.png" )} alt="Chris" />
            <p>After some consideration by the judging panel, we here at Smily Stylist thought it was time to include the handsome host of the Bachelor in the running for this years Venny. His smile brings us joy and ensures that the final rose ceremony is truly magical. As the only male nomination this year, Chris is in for some stiff competition from the ladies.</p>
            <h3>Caroline</h3>
            <img src={ require("../../images/desktop/_blog/pics/Caroline.png" )} alt="Caroline" />
            <p>There is something about a New England smile that just gets to you, and Caroline emphasises this with her beautiful smile. Although not successful on the show, this 26-year-old realtor stole dentists hearts with her award-winning smile. With no gaps between the teeth and symmetrical gum line, Caroline might be the dark horse in the competition.</p>
            <p>By unanimous vote, the winner of the 2018 Golden Veneer’s: The Bachelor edition is…</p>
            <img src={ require("../../images/desktop/_blog/pics/Winner  - Caroline.png" )} alt="Winner - Caroline" />
            <p>CAROLINE from Holliston, MA!</p>
            <p>This is the first Venny awarded to a resident of Massachusetts, and we could not be happier to award this stunning woman her first Golden Veneer. Caroline’s smile will go down in history as one of the best on the show. Did you watch the show? Who was your favourite smile?  Tweet at us at
              <a href="https://twitter.com/smilestylistTO">@SmileStylistTO</a> and let us know!</p>
          </div>
          <div class="col-sm-4 blog-right">
            <img alt="Free Teeth Whitening" src={ require("../../images/desktop/services/free-teeth-whitening.jpg" )} class="img-responsive wow animated zoomIn" data-wow-delay="0.4s" /><br />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage